import React from 'react'
import { Category, scrollToForm } from '../../contact/ContactForm'

const ServiceOutsourcingOfferCta = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="lead text-left text-sm-right">
            <div className="lead text-muted">
              <h1 className="seo-span text-success">
              Software-Outsourcing nach Polen
              </h1>
              <br />
              Bringen Sie Ihr Unternehmen auf die nächste Stufe
            </div>
            <a
              href="#contact-partial"
              data-form-category="outsourcing"
              className="btn btn-success my-2"
              id="software-outsourcing-more-btn"
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.FreeConsultation, 0, 500)
              }}
            >
              Holen Sie sich eine kostenlose Beratung
            </a>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="seo-p">
          Unser Team von Nearshore-Entwicklern ist bereit, mit der Arbeit an Ihrer App zu beginnen 
          (einschließlich der Festlegung der Anforderungen und des Arbeitsumfangs). 
          Offshore-Outsourcing hilft Ihnen, Ihr Projekt zu entwickeln, ohne neues Personal einzustellen, 
          und Sie zahlen nur für das, was Sie tatsächlich bekommen. Durch das Outsourcing nach Polen können 
          Sie bis zu 30 % der Kosten einsparen, und ein engagiertes Zaven-Team wird zu einem integralen Bestandteil 
          Ihres Unternehmens.
          </div>
          <p className="text-sm text-muted">
            Unser&nbsp;
            <strong className="seo-regular">
            Nearshore/Offshore-Outsourcing-Team
            </strong>
            umfasst die besten Fachleute: Junior- und Senior-Level-Programmierer, QA-Tester, Projektmanager, Business-Analysten, 
          SCRUM-Master und engagierte Account-Manager.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingOfferCta
