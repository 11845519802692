import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faEye,
  faGem,
  faUmbrella,
} from '@fortawesome/pro-light-svg-icons'

const ServiceOutsourcingOffer = () => (
  <section className="service-outsourcing py-5 bg-faded">
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12 col-md-4">
          <h4 className="seo-h2 text-sm-center text-md-right mt-0">
            Time & materials
            <br />
            model
          </h4>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="card-group mb-5">
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faEye}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>TRANSPARENZ</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                  Mit monatlichen Zahlungen ist es einfacher, die finanzielle Kontrolle 
                  über Ihr Projekt zu behalten. Keine versteckten Kosten, keine Überraschungen.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faClock}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Verfügbarkeit</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                  Engagierte Programmierer, die nur an Ihrem Projekt arbeiten
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faUmbrella}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Sicherheit</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                  Der Code ist dank Sicherheitsvereinbarungen und Arbeitsrichtlinien, 
                  die sich streng an die Sicherheitsverfahren halten, immer sicher.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faGem}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Qualität</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                Unser Entwicklungsteam besteht aus erfahrenen Programmierern, 
                die sich auf die Unternehmensziele konzentrieren.
                </p>
              </div>
            </div>
          </div>
          <p>
            <strong>Time & Materials</strong> ist ein für beide Seiten vorteilhaftes Zahlungsmodell. 
            Die Transparenz dieses Modells bedeutet, dass der Kunde mehr Kontrolle über die Kosten 
            hat und nur für die tatsächliche Arbeit bezahlt Diese Budgetierungsmethode erlaubt es uns, 
            einen straffen Arbeitsablauf beizubehalten und ermöglicht es uns, das Projekt in bequemen 
            Tranchen zu finanzieren.
          </p>
          <p>
          Die wichtigsten Vorteile von <strong>Time & Materials</strong> sind:
          </p>
          <ul>
          <li>Ständige Qualitätskontrolle</li>
          <li>Geringe Risikofaktoren</li>
          <li>Keine zusätzlichen Kosten</li>
          <li>Projektmanager-Unterstützung für einen optimierten Arbeitsablauf</li>
          </ul>
          <p>
          Dies ist großartig für Startups, denen es oft an detaillierten 
          Produktspezifikationen, Anwendungsprototypen und Zeitvorgaben fehlt.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingOffer
