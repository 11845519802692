import React from 'react'

const ServiceOutsourcingDevelopmentModels = () => (
  <section className="service-outsourcing py-5">
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h4 className="seo-h2 text-sm-center">Entwicklungsmodelle</h4>
          <div className="lead my-2 text-sm-center">
          Es gibt verschiedene Modelle für das Outsourcing der Entwicklung von Web- und mobilen Anwendungen 
          Wir sind aufgeschlossen und stellen uns auf Ihre Bedürfnisse und Ihr Produkt ein. Das einzige, was 
          sich nie ändern wird, sind unsere anspruchsvollen Standards.
          </div>
        </div>
      </div>

      <div className="row my-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="seo-h3 mt-2">
            <span className="text-muted display-4 float-left mr-3">NDC</span>
            Nearshore <br />
            <span className="text-success">
              development <br />
              center
            </span>
          </h2>
          <br className="clearfix" />
          <div className="seo-p">
            <strong>Eine großartige Lösung für alle europäischen Unternehmen. </strong>. Unsere Teammitglieder sprechen Englisch und Deutsch. 
             
            <h2 className="seo-span">Nearshore-outsourcing</h2>
            und die westliche Denkweise machen es uns leichter, langfristige Geschäftsbeziehungen ohne Grenzen aufzubauen.
          </div>
          <div className="text-sm text-muted">
          Dank der guten Flugverbindungen nach und von Polen können wir uns regelmäßig mit unseren Partnern aus Europa treffen. Jetzt können Sie sich auf Ihr Geschäft
               konzentrieren und uns erschwingliche mobile und Web-Apps für Sie erstellen lassen. Unser Team ist eine Gruppe von talentierten und leidenschaftlichen technischen Experten,
                die nach agilen Methoden arbeiten. Wir freuen uns, Sie in unser Büro in Wrocław - der polnischen Hauptstadt der Technologie - einzuladen. 
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <h2 className="seo-h3 mt-2">
            <span className="text-muted display-4 float-left mr-3">ODC</span>
            Offshore <br />
            <span className="text-success">
              development <br />
              center
            </span>
          </h2>
          <br className="clearfix" />
          <div className="seo-p">
            <strong>
              Konzipiert für Geschäftspartner in den USA, Kanada, Skandinavien, Asien und Australien,
            </strong>
            &nbsp; die Wert auf gut ausgebildete und geschulte Experten, englischsprachiges Management, 
              europäische Rechts- und Sicherheitsstandards und hochwertigen Code legen.
          </div>

          <div className="text-sm text-muted">
          ODC ermöglicht uns die Zusammenarbeit mit Kunden aus der ganzen Welt. 
              Die Entwicklung des Offshoring in Polen ist für ausländische Partner sehr 
              vorteilhaft: Polen ist Mitglied der Europäischen Union und des Schengener Abkommens, 
              daher ist der Wechselkurs sehr stabil und die Projektkosten sind leicht abzuschätzen. 
              Weltklasse-Standards, Effizienz und Geschwindigkeit machen uns zum idealen langfristigen Geschäftspartner.
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <h2 className="seo-h3 mt-2">
            <span className="text-muted display-4 float-left mr-3">DDC</span>
            Dedicated <br />
            <span className="text-success">
              development <br />
              center
            </span>
          </h2>
          <br className="clearfix" />
          <div className="seo-p">
            <strong>Ideal für größere IT-Agenturen, </strong>, die eine bestimmte
             Menge an Arbeit auslagern müssen, ohne neues Personal einzustellen.
          </div>
          <div className="text-sm text-muted">
          Dieses Modell ermöglicht es Ihnen, ein engagiertes <strong>Expertenteam</strong> auf Vollzeitbasis zu engagieren. 
          Unsere Spezialisten kennen den Markt, und da sie nur an Ihrem Projekt arbeiten werden, 
          haben ein gutes Verständnis für Ihr Produkt und wissen genau, wie sie es auf die nächste 
          Stufe bringen können. Alles, was Sie tun müssen, ist, einen Ihrer Mitarbeiter zu benennen, 
          der die Anleitung gibt und Berichte erhält. Wenn Sie interessiert sind, senden Sie uns eine 
          kurze Beschreibung Ihres Projekts und wir werden Ihnen ein individuelles Angebot für die 
          Einstellung eines engagierten Entwicklerteams erstellen.
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingDevelopmentModels
